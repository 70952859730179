<template>
    <DetailItemEditCard :item-name="'project'" :promises.sync="promises"
    title="Nieuwe taak aanmaken"
    :detailViewRouteLocation="{ name: RouteNames.PROJECT_DETAIL, params: {clientId: clientId, projectId: projectId} }"
    confirmationMessage="Taak werdt succesvol aangemaakt" update-store-action="tasksModule/createTask"
    :update-store-action-payload="newTask" :success-callback="taskCreatedSuccessCallback">
    <template v-slot:form-fields>
      <v-row>
        <v-col cols="12" md="6">
          <v-autocomplete :items="projects" type="text" v-model="newTask.projectId" label="Project" required :rules="projectRules"
            item-text="projectName" item-value="id" tabindex="1" disabled>
            <template v-slot:item="slotProps">
              <v-list-item-content class="notranslate" v-text="slotProps.item.projectName" />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field v-model="newTask.taskName" persistent-placeholder label="Taak naam" type="text"
            :rules="taskNameRules" required @blur="fetchGeneratedFunctionalId()" />
        </v-col>
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="10">
              <v-text-field v-model="functionalIdWithPrefix" persistent-placeholder label="Taak id" type="text"
                :rules="[...getRequiredRules('Id van de taak moet ingevuld worden'), 
                  ...getStartsWithRules('T-', 'Id van een taak moet beginnen met T-'), 
                  ...getMaxStringSizeRules(5, 'Id van een taak mag maximaal 3 tekens bevatten')]"
                required />
            </v-col>
            <v-col cols="2" class="d-flex justify-center align-center" >
              <v-icon v-if="showGenerateFunctionalIdButton"
                @click="resetToGeneratedFunctionalId()" title="Gebruik de automatische id" color="red">
                mdi-undo
              </v-icon>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete v-model="newTask.taskFrequency" :items="taskFrequencyValues" label="Frequentie"
           :rules="[...getRequiredRules('Frequentie moet ingevuld worden')]" required>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <DatePicker v-model="newTask.startDate" label="Start datum" :default-now="false" />
        </v-col>
        <v-col cols="12" md="6">
          <DatePicker v-model="newTask.endDate" label="Eind datum"
            :min="newTask.startDate ? new Date(newTask.startDate) : null" :default-now="false" />
        </v-col>
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="4">
              <v-text-field required persistent-placeholder label="Duurtijd" type="number"
                v-model="newTask.durationAmount" min=1
                :rules="[ ...getBiggerThanZeroRules('Aantal moet groter zijn dan 0'), ...getRequiredRules('Aantal moet ingevuld worden')]">
              </v-text-field>
            </v-col>
            <v-col cols="8">
              <v-autocomplete v-model="newTask.durationUnit" :items="timeUnitValues"
                :rules="[...getRequiredRules('Tijdseenheid is verplicht')]" required disabled>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
        <v-col lg="4" md="8" sm="8" xs="12">
          <v-textarea outlined label="Beschrijving" clearable clear-icon="mdi-close-circle"
            :rules="[v => (v == null || v.length <= 600) || 'Beschrijving kan maximaal uit 600 karakters bestaan']"
            v-model="newTask.description" />
        </v-col>
      </v-row>
    </template>
  </DetailItemEditCard>
</template>
  
<script>
import DetailItemEditCard from "@/components/shared/DetailItemEditCard.vue"
import DatePicker from "@/components/shared/DatePicker.vue"
import RouteNames from "@/router/RouteNames"
import { getRequiredRules, getBiggerThanZeroRules, getStartsWithRules, getMaxStringSizeRules } from "@/shared/utils/inputRulesUtils.js"
import TaskFrequencyType from "../../../shared/enums/taskFrequencyType"
import TimeUnitType from "../../../shared/enums/timeUnitType"
import { translateTaskFrequency, translateTimeUnit } from "../../../shared/utils/translateUtils"

export default {
  name: "TaskCreate",
  components: {
    DetailItemEditCard,
    DatePicker,
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    clientId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      newTask: {
        clientId: this.clientId,
        projectId: this.projectId,
        functionalId: null,
        taskName: null,
        startDate: null,
        endDate: null,
        durationUnit: TimeUnitType.HOURS,
        durationAmount: 1,
        taskFrequency: TaskFrequencyType.ONE_TIME,
        description: null
      },
      lastFetchedFunctionalIdSuggestion: null,
      projects: [],
      RouteNames,
      projectRules: getRequiredRules("Project moet ingevuld worden"),
      taskNameRules: getRequiredRules("Naam van de taak is verplicht"),
      promises: [this.fetchProjects()],
      TaskFrequencyType,
      TimeUnitType,
      taskFrequencyValues: [
        {
          text: translateTaskFrequency(TaskFrequencyType.WEEKLY),
          value: TaskFrequencyType.WEEKLY
        }, 
        {
          text: translateTaskFrequency(TaskFrequencyType.ONE_TIME),
          value: TaskFrequencyType.ONE_TIME
        }
      ],
      timeUnitValues: [ 
        {
          text: translateTimeUnit(TimeUnitType.HOURS),
          value: TimeUnitType.HOURS
        },
        {
          text: translateTimeUnit(TimeUnitType.MINUTES),
          value: TimeUnitType.MINUTES
        },
      ]
    }
  },
  methods: {
    taskCreatedSuccessCallback(taskCreatedDTO) {
      this.$router.push({ name: RouteNames.TASK_DETAIL, params: { clientId: taskCreatedDTO.data.clientId, projectId: taskCreatedDTO.data.projectId, functionalId: taskCreatedDTO.data.functionalId } })
    },
    fetchProjects() {
      this.$store.dispatch("clientsModule/fetchClient", this.clientId)
        .then(result => this.projects = result.projects)
    },
    fetchGeneratedFunctionalId() {
      if (this.newTask.taskName) {
        this.$store.dispatch("tasksModule/getFunctionalIdSuggestions", {clientId: this.clientId, projectId: this.projectId, seed: this.newTask.taskName})
          .then(result => {
            this.newTask.functionalId = result
            this.lastFetchedFunctionalIdSuggestion = result
          })
      }
    },
    resetToGeneratedFunctionalId() {
      if (this.lastFetchedFunctionalIdSuggestion) {
        this.newTask.functionalId = this.lastFetchedFunctionalIdSuggestion
      } else {
        this.fetchGeneratedFunctionalId()
      }
    },
    getBiggerThanZeroRules,
    getRequiredRules,
    getStartsWithRules,
    getMaxStringSizeRules
  },
  computed: {
    functionalIdWithPrefix: {
      get() {
        // Ensure the prefix "T-" is always included
        if (!this.newTask.functionalId) return ''
        if (!this.newTask.functionalId.startsWith('T-')) return 'T-'
        return this.newTask.functionalId
      },
      set(value) {
        // Ensure the value cannot be stripped below "T-" (after typing)
         if (!value.startsWith('T-')) {
          if (value[1] !== '-') {
            this.newTask.functionalId = 'T-' + value.replace(/^T/, '').toUpperCase()
          } else {
            this.newTask.functionalId = 'T-' + value.replace(/^T-/, '').toUpperCase()
          }
        } else {
          this.newTask.functionalId = value.toUpperCase()
        }
      }
    },
    showGenerateFunctionalIdButton() {
      return this.lastFetchedFunctionalIdSuggestion !== this.newTask.functionalId 
    }
  },
}
</script>